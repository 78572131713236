<template>
  <div class="refunds">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-select v-model="statusValue" placeholder="全部类型" @change="onSearch" clearable>
          <el-option v-for="item in status" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-input v-model="search1" placeholder="搜索商超名称" suffix-icon="el-icon-search" clearable @change="onSearch"></el-input>
        <el-input v-model="search2" placeholder="搜索商品名称" suffix-icon="el-icon-search" clearable @change="onSearch"></el-input>
        <el-input v-model="barCode" placeholder="搜索商品条形码" suffix-icon="el-icon-search" clearable @change="onSearch"></el-input>
        <el-button type="primary" plain @click="handleDownload">下载模板</el-button>

        <!-- <el-upload class="upload-excel" :action="action" accept=".xls,.xlsx" :limit="1" :headers="headers" :before-upload="beforeUpload" :file-list="fileList" :show-file-list="false" :on-success="onSuccess">
          <el-button type="primary">上传</el-button>
        </el-upload> -->

        <el-button class="btn" type="primary" @click="handleEnter">导入</el-button>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="productName" label="商品名称"></el-table-column>
          <!-- <el-table-column show-overflow-tooltip min-width="120" prop="productCode" label="商品编号"></el-table-column> -->
          <el-table-column show-overflow-tooltip min-width="120" prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="categoryName" label="商品分类"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="100" prop="returnsCount" label="数量"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="100" prop="productPrice" label="单价"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="100" prop="totalAmount" label="总价"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="100" prop="" label="类型">
            <template slot-scope="scope">
              {{ scope.row.returnsType == 1 ? "退货" : "换货" }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="shopName" label="商超名称"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="170" prop="createdTime" label="申请时间"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="120" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" plain @click="handleShowDetail(scope.row.id)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <!-- 导入申请弹窗 -->
    <el-dialog title="导入" :visible.sync="importShow" :before-close="onImportCancel">
      <el-form label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="上传附件" required>
              <el-upload :action="apiBaseUrl" :headers="apiHeaders" :file-list="importFiles" accept=".xlsx,.xls" :on-change="beforeUpload" :auto-upload="false" :limit="1" :on-exceed="handleExceed" :on-remove="handleRemove">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip" style="color: red">提示：只能上传"xlsx"、"xls"文件，且不超过5MB</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onImportCancel">关 闭</el-button>
        <el-button type="primary" @click="submitFormImport('importRuleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      search1: "",
      search2: "",
      barCode: "",
      statusValue: "",
      // action: this.$axios.defaults.baseURL + this.$api.importProductReturns,
      // headers: { token: this.$store.state.token },
      // fileList: [],

      // 导入弹窗数据
      apiBaseUrl: this.$axios.defaults.baseURL + this.$api.importProductReturns,
      apiHeaders: {
        token: this.$store.state.token,
      },
      importShow: false,
      importFiles: [], //文件列表
      // 状态
      status: [
        { value: 1, label: "退货" },
        { value: 2, label: "换货" },
      ],
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getRefundList()
  },
  methods: {
    // 获取列表
    getRefundList() {
      this.loading = true
      this.$axios
        .get(this.$api.productReturnsList, {
          params: {
            returnsType: this.statusValue,
            shopName: this.search1,
            productName: this.search2,
            barCode: this.barCode,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 下载
    handleDownload() {
      // window.open('http://43.136.39.139:8300/files/default/退换货申请导入模版.xlsx')
      const link = document.createElement("a")
      link.style.display = "none"
      link.href = "https://校园生活网.com/files/default/退换货申请导入模版.xlsx"
      link.download = "退换货申请导入模版.xlsx"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    // // 上传校验文件类型
    // beforeUpload (file) {
    //   const allowedExtensions = ['xls', 'xlsx'];
    //   const fileExtension = file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2).toLowerCase();

    //   if (!allowedExtensions.includes(fileExtension)) {
    //     this.$message.error('只能上传 .xls 或 .xlsx 格式的 Excel 文件！');
    //     return false;
    //   }

    //   return true;
    // },
    // // 上传成功
    // onSuccess (res) {
    //   if (res.code == 100) {
    //     this.$message.success('上传成功');
    //     this.tableData = []
    //     this.getRefundList();
    //   } else {
    //     this.$message.error(res.desc);
    //   }
    // },

    // 导入
    handleEnter() {
      console.log(this.importShow)
      this.importShow = true
    },
    // 导入弹窗关闭
    onImportCancel() {
      this.importShow = false
      this.importFiles = []
      this.getRefundList()
    },
    beforeUpload(file) {
      console.log(file)
      const suffixArr = ["xlsx", "xls"]
      const maxSize = 1024 * 1024 * 5
      if (!suffixArr.includes(file.name.split(".").pop())) {
        this.$message.error(`只能上传"xlsx"、"xls"文件`)
        this.importFiles = []
        return false
      }
      if (file.size > maxSize) {
        this.$message.error("附件不能超过5M")
        this.importFiles = []
        return false
      }
      this.importFiles.push(file.raw)
    },
    handleExceed() {
      this.$message.warning(`只能上传一个文件！`)
    },
    handleRemove() {
      this.importFiles = []
    },
    // 导入表单校验
    submitFormImport() {
      if (!this.importFiles || this.importFiles.length == 0) {
        return this.$message.warning("请先上传文件")
      }
      let parmas = new FormData()
      parmas.append("file", this.importFiles[0])
      this.$axios.post(this.$api.importProductReturns, parmas).then(res => {
        if (res.data.code == 100) {
          this.$message.success("导入成功!")
          this.onImportCancel()
        }
      })
    },

    // 搜索
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getRefundList()
    },
    // 详情
    handleShowDetail(id) {
      this.$router.push({ name: "refundsDetail", query: { id } })
    },
    // 换页
    handleCurrentChange(val) {
      this.tableData = []
      this.currentPage = val
      this.getRefundList()
    },
    // 切换每页显示的条数
    handleSizeChange(val) {
      this.tableData = []
      this.pageSize = val
      this.getRefundList()
    },
  },
}
</script>

<style scoped lang="scss">
.refunds {
  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-select,
    .el-input,
    .el-button {
      margin-left: 20px;
    }

    .el-input {
      width: 220px;
    }
  }
}
</style>
